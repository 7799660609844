import { mapIsoStringToDate } from '@metaswiss/lib';
import { isValid, parse, parseISO } from 'date-fns';

export type GroupDataByDay<T> = {
  [day: string]: T[];
};

const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z$/;
const simpleDatePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

const isValidDateDay = (dateString: string): boolean => {
  const isDateValid = (date: Date): boolean => isValid(date);

  if (isoDatePattern.test(dateString)) {
    const parsedDate = parseISO(dateString);
    return isDateValid(parsedDate);
  }

  if (simpleDatePattern.test(dateString)) {
    const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm', new Date());
    return isDateValid(parsedDate);
  }

  return false;
};

export const groupDataByDate = <T extends { createdAt: string; updatedAt?: string }>(
  inputData: T[]
): GroupDataByDay<T>[] => {
  const groupedData: GroupDataByDay<T> = {};
  inputData.forEach((item) => {
    const createdAtDate = isValidDateDay(item.createdAt)
      ? mapIsoStringToDate({ date: item.createdAt })
      : item.createdAt;

    const day = createdAtDate;

    if (!groupedData[day]) {
      groupedData[day] = [];
    }
    groupedData[day].push({
      ...item,
      createdAt: createdAtDate,
      updatedAt: item.updatedAt,
    });
  });

  const mapGroupedData = Object.entries(groupedData).map(([day, values]) => ({
    [day]: values,
  }));

  return mapGroupedData;
};

export type GroupDataByYear<T> = {
  [year: string]: T[];
};

const isValidDateYear = (dateString: string): boolean => !isNaN(Date.parse(dateString));

export const groupDataByYear = <T extends { [key: string]: unknown }>(
  data: T[],
  dataKey: keyof T = 'publishedAt'
): GroupDataByYear<T>[] => {
  const groupedData: GroupDataByYear<T> = {};

  data.forEach((item) => {
    const dateValue = item[dataKey] as string;

    const dateArray = dateValue.split('/');
    const formattedDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;

    if (isValidDateYear(formattedDate)) {
      const date = new Date(formattedDate);
      const year = date.getFullYear().toString();

      if (!groupedData[year]) {
        groupedData[year] = [];
      }

      groupedData[year].push({
        ...item,
      });
    }
  });

  return Object.entries(groupedData)
    .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
    .map(([year, values]) => ({
      [year]: values,
    }));
};
