import { AddCaseDocumentsRequest } from '@metaswiss/api';
import { convertDocumentSize, remSize } from '@metaswiss/lib';
import {
  Button,
  CircleInteractiveIcon,
  Flex,
  LinkAngled,
  SendIcon,
  ThemedIcon,
  UploadedDocument,
} from '@metaswiss/ui-kit';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, FC, useCallback, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { api } from '../../../../../../api/msApi';
import ControlledForm from '../../../../../../components/controlled-form/ControlledForm';
import { FileStatus } from '../../../../../../enums/fileStatus';
import { ApiResource } from '../../../../../../enums/resource.enum';
import { useTextTranslation } from '../../../../../../hooks/use-text-translation/useTextTranslation';
import { getQueryKey } from '../../../../../../shared/helpers/getQueryKey.helper';

import {
  AttachDocumentsInput,
  CaseChatWrapper,
  ChatTextarea,
  DocumentsWrapper,
  InputUploadWrapper,
  SingleDocumentWrapper,
  TextareaWrapper,
} from './caseChat.styles';
import { caseChatSchema, CreateCaseChatFormData, createCaseChatSchema } from './caseChatSchema';

const caseChatDefaultValues: CreateCaseChatFormData = {
  message: '',
  files: [],
};

type Props = {
  caseId: string;
};

const CaseChatView: FC<Props> = ({ caseId }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { textTranslation } = useTextTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { isDirty },
  } = useFormContext<CreateCaseChatFormData>();

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = 'auto';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight / remSize}rem`;
  };

  const getDocuments = useCallback(() => getValues().files || [], [getValues]);

  const getDocumentsAndSelectedDocumentIndex = useCallback(
    (selectedFile: File) => {
      const uploadedFiles = getDocuments();

      const fileIndex = uploadedFiles.findIndex((file) => file.file === selectedFile);

      return { uploadedFiles, fileIndex };
    },
    [getDocuments]
  );

  const documents = watch(caseChatSchema.files);

  const { mutateAsync: uploadFile } = useMutation({
    mutationFn: (file: File) => {
      return api.assets.uploadUnassignedFile({ file: file, fileType: file.type });
    },
    onSuccess: async (response, variables) => {
      const { uploadedFiles, fileIndex } = getDocumentsAndSelectedDocumentIndex(variables);
      const url = await queryClient.fetchQuery({
        queryKey: getQueryKey(ApiResource.ASSET, response.fileId),
        queryFn: () => api.assets.getS3SignedUnassignedUrl({ assetId: response.fileId }),
      });
      uploadedFiles[fileIndex] = {
        ...uploadedFiles[fileIndex],
        url: url.url,
        fileId: response.fileId,
        status: FileStatus.COMPLETED,
      };
      setValue(caseChatSchema.files, uploadedFiles, { shouldValidate: true, shouldDirty: true });
    },
    onError: (_, variables) => {
      const { uploadedFiles, fileIndex } = getDocumentsAndSelectedDocumentIndex(variables);

      uploadedFiles[fileIndex] = {
        ...uploadedFiles[fileIndex],
        status: FileStatus.FAILED,
      };

      setValue(caseChatSchema.files, uploadedFiles);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (caseDocuments: AddCaseDocumentsRequest) => {
      return api.case.addCaseDocuments(caseId, {
        documentsIds: caseDocuments.documentsIds,
        message: caseDocuments.message,
      });
    },
    onSuccess: () => {
      reset({
        message: '',
        files: [],
      });
      queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE_CONVERSATION, caseId) });
      queryClient.invalidateQueries({ queryKey: getQueryKey(ApiResource.CASE, caseId) });
    },
  });

  const onSubmit = (data: CreateCaseChatFormData) => {
    const files = data.files?.length
      ? { documentsIds: data.files.map((file) => file.fileId).filter((id): id is string => id !== undefined) }
      : { documentsIds: [] };

    const caseConversationReq: AddCaseDocumentsRequest = {
      documentsIds: files.documentsIds,
      message: data.message,
    };

    mutate(caseConversationReq);
  };

  const handleDocumentUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      for (const file of files) {
        const uploadedFiles = getDocuments();

        uploadedFiles.push({
          fileId: '',
          url: '',
          size: convertDocumentSize(file.size),
          name: file.name,
          status: FileStatus.PROCESSING,
          file,
        });
        setValue(caseChatSchema.files, uploadedFiles);

        await uploadFile(file);
      }
    }
  };

  const handleDocumentDelete = (selectedFile: File) => {
    const { uploadedFiles, fileIndex } = getDocumentsAndSelectedDocumentIndex(selectedFile);
    uploadedFiles.splice(fileIndex, 1);
    setValue(caseChatSchema.files, uploadedFiles, { shouldValidate: true, shouldDirty: true });
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleDocumentRetry = (selectedFile: File) => {
    const { uploadedFiles, fileIndex } = getDocumentsAndSelectedDocumentIndex(selectedFile);

    uploadedFiles[fileIndex] = {
      ...uploadedFiles[fileIndex],
      status: FileStatus.PROCESSING,
    };

    setValue(caseChatSchema.files, uploadedFiles, { shouldValidate: true, shouldDirty: true });

    uploadFile(uploadedFiles[fileIndex].file as File);
  };

  const handleDocumentClick = (url: string) => {
    window.open(url, '_blank');
  };

  const areFilesLoading = useMemo(
    () => getDocuments().some((file) => file.status === FileStatus.PROCESSING),
    [getDocuments]
  );

  const hasFailedFileUploads = useMemo(
    () => getDocuments().some((file) => file.status === FileStatus.FAILED),
    [getDocuments]
  );
  const renderDocuments = useCallback(() => {
    return (
      <DocumentsWrapper>
        {getDocuments()?.map((document, index) => {
          return (
            <SingleDocumentWrapper key={`${document.fileId}${index}`}>
              <UploadedDocument
                title={document.name || ''}
                documentSize={document.size || ''}
                onDocumentClick={() => handleDocumentClick(document.url || '')}
                onDeleteDocumentClick={() => handleDocumentDelete(document.file as File)}
                hasUploadFailed={document.status === FileStatus.FAILED}
                failedUploadTitle={textTranslation('global.failedDocumentTitle')}
                failedUploadSubtitle={textTranslation('global.failedDocumentSubtitle')}
                onFailedDocumentClick={() => handleDocumentRetry(document.file as File)}
                isLoading={document.status === FileStatus.PROCESSING}
              />
            </SingleDocumentWrapper>
          );
        })}
      </DocumentsWrapper>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  return (
    <CaseChatWrapper>
      <Flex flexDirection={'column'} gap={'1rem'}>
        {getDocuments().length > 0 && renderDocuments()}
        <Flex alignItems={'flex-start'} justifyContent={'space-between'} gap={'1rem'}>
          <InputUploadWrapper>
            <Flex alignItems={'flex-start'} gap={'1rem'}>
              <AttachDocumentsInput type="file" multiple ref={inputRef} onChange={handleDocumentUpload} />
              <CircleInteractiveIcon
                icon={LinkAngled}
                customSize={'2.75rem'}
                onClick={() => inputRef.current?.click()}
              />

              <TextareaWrapper>
                <Controller
                  control={control}
                  name={caseChatSchema.message}
                  render={({ field: { value, onChange: onTextareaChange } }) => (
                    <ChatTextarea
                      rows={1}
                      value={value}
                      onChange={(e) => {
                        const trimmedValue = e.target.value.trimStart();
                        handleTextareaChange(e);
                        onTextareaChange(trimmedValue);
                      }}
                      placeholder={textTranslation('cases.typeSomething')}
                    />
                  )}
                />
              </TextareaWrapper>
            </Flex>
          </InputUploadWrapper>
          <Button
            onClick={handleSubmit(onSubmit)}
            text={textTranslation('global.sent')}
            size={'medium'}
            loading={isLoading}
            type="submit"
            renderEndIcon={() => (
              <ThemedIcon icon={SendIcon} size={'small'} customStrokeColor={theme.v2.text.onAction} />
            )}
            disabled={!isDirty || areFilesLoading || hasFailedFileUploads}
          />
        </Flex>
      </Flex>
    </CaseChatWrapper>
  );
};

export const CaseChat: FC<Props> = ({ caseId }) => {
  return (
    <ControlledForm validationSchema={createCaseChatSchema} defaultValues={caseChatDefaultValues}>
      <CaseChatView caseId={caseId} />
    </ControlledForm>
  );
};
